<template>
    <div>
        <form @submit.prevent="validateForm($event)">
            <p v-if="error" class="invalid red">{{ error }}</p>
            <div class="form-element">
                <Input
                    @change="
                        errors.email = false;
                        errors.email_format = false;
                    "
                    name="email"
                    :label="$t('login.email')"
                    :placeholder="$t('login.email')"
                    :required="true"
                    :class="{ invalid: errors.email || errors.email_format }"
                    :remark="
                        errors.email
                            ? { message: $t('error.email') }
                            : errors.email_format
                            ? { message: $t('error.email-format') }
                            : null
                    "
                />
            </div>

            <div class="form-element">
                <Input
                    @change="errors.password = false"
                    name="password"
                    :label="$t('login.password')"
                    :placeholder="$t('login.password')"
                    :required="true"
                    type="password"
                    :class="{ invalid: errors.password }"
                    :remark="
                        errors.password
                            ? { message: $t('error.password') }
                            : null
                    "
                />
            </div>

            <div class="bottom right">
                <button type="submit">
                    <span>
                        {{ $t("login.catch") }}
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Input from "@/components/form/Input";
import { setCookie, getCookie } from "@/helpers";
import axios from "axios";

export default {
    components: {
        Input
    },
    data: function() {
        return {
            error: null,
            errors: {
                email: false,
                email_format: false,
                password: false
            }
        };
    },
    methods: {
        validateEmail(email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validateForm(event) {
            let email = document.querySelector("input[name=email]").value;
            let password = document.querySelector("input[name=password]").value;

            if (!email) {
                this.errors.email = true;
            } else if (!this.validateEmail(email)) {
                this.errors.email_format = true;
            }
            if (!password) {
                this.errors.password = true;
            }

            // Check if errors are present
            if (
                this.errors.email ||
                this.errors.email_format ||
                this.errors.password
            ) {
                document.getElementById("login").scrollIntoView();
            } else {
                const credentials = {
                    email,
                    password
                };
                this.login(credentials);
            }
        },
        login(credentials) {
            this.$emit("submit");
            axios
                .post(`${this.$apiroute}/login`, credentials)
                .then(response => {
                    // CHECK COOKIE CONSENT
                    setCookie("token", response.data.token);

                    // Try to get me info
                    // Secure way: httpolny cookies
                    axios
                        .get(`${this.$apiroute}/auth/me`, {
                            headers: {
                                Authorization: `Bearer ${getCookie("token")}`
                            }
                        })
                        .then(response => localStorage.setItem("me", response));
                    this.$emit("success");
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.error = this.$t("error.no-match");
                    } else {
                        this.error = this.$t("error.server-connection");
                    }
                    this.$emit("failed");
                });
        }
    }
};
</script>

<style lang="scss">
form {
    .bottom {
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 750px) {
                flex-direction: column;
                align-items: flex-start;

                button {
                    width: 100%;
                    margin-top: 3.5rem;
                }
            }
        }
    }

    .invalid {
        input,
        select {
            border-bottom: 3px solid $red;
        }
    }
}
</style>
