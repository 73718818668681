<template>
    <div class="input">
        <label :for="name"
            >{{ label }} <span v-if="required" class="asterix">*</span></label
        >
        <input
            :id="name"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :value="value"
        />
        <p v-if="remark" class="remark">{{ remark.message }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: { default: "label" },
        name: { default: "name" },
        type: { default: "text" },
        placeholder: { default: "Placeholder" },
        remark: { default: null },
        required: { type: Boolean },
        value: { type: String }
    }
};
</script>

<style lang="scss">
.input {
    width: 100%;
    display: flex;
    flex-direction: column;

    * {
        font-size: 18px;
        letter-spacing: 0.1em;
    }

    label {
        font-family: "Titillium Web", sans-serif;
        margin-bottom: 1rem;
    }

    input {
		border: none;
        border-bottom: 3px solid $black;
        box-sizing: border-box;
        padding: 0.8rem 1rem;
        min-height: 3.5rem;
        transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
		background-color: $washed-red;

        &::placeholder {
            color: $grey;
        }

        &:focus {
            outline: none;
            border-bottom: 3px solid $blue;
        }
    }

    .remark {
        margin: 0;
        color: $red;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}
</style>
