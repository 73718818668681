<template>
    <div class="login content">
        <main>
            <section class="header" id="login">
                <h2 class="title">{{ $t("login.title") }}</h2>
                <p class="text-right" v-html="$t('login.text')"></p>
                <div class="login">
                    <LoginForm
                        @success="redirect"
                        @failed="$emit('done')"
                        @submit="loading"
                    />
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import LoginForm from "@/components/form/LoginForm";

export default {
    components: {
        LoginForm
    },
    data: function() {
        return {};
    },
    methods: {
        loading() {
            this.$emit("loading");
        },
        redirect() {
            this.$emit("done");
            this.$router.push("/portfolio");
        }
    },
    metaInfo() {
        return {
            title: `${this.$root.$i18n.locale === "en" ? "Login" : "Inloggen"}`,
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Login - Renders by Julie"
                },
                {
                    name: "description",
                    content: "Loginpagina"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "robots",
                    content: "noindex, nofollow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                }
            ]
        };
    }
};
</script>

<style lang="scss">
#login {
    @media (max-width: 700px) {
        * {
            text-align: left;
        }
    }
}
</style>
