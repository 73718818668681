var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[(_vm.error)?_c('p',{staticClass:"invalid red"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"form-element"},[_c('Input',{class:{ invalid: _vm.errors.email || _vm.errors.email_format },attrs:{"name":"email","label":_vm.$t('login.email'),"placeholder":_vm.$t('login.email'),"required":true,"remark":_vm.errors.email
                        ? { message: _vm.$t('error.email') }
                        : _vm.errors.email_format
                        ? { message: _vm.$t('error.email-format') }
                        : null},on:{"change":function($event){_vm.errors.email = false;
                    _vm.errors.email_format = false;}}})],1),_c('div',{staticClass:"form-element"},[_c('Input',{class:{ invalid: _vm.errors.password },attrs:{"name":"password","label":_vm.$t('login.password'),"placeholder":_vm.$t('login.password'),"required":true,"type":"password","remark":_vm.errors.password
                        ? { message: _vm.$t('error.password') }
                        : null},on:{"change":function($event){_vm.errors.password = false}}})],1),_c('div',{staticClass:"bottom right"},[_c('button',{attrs:{"type":"submit"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("login.catch"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }